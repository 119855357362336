import React from "react"
import SEO from "../components/seo"
import BaseLayout from "../components/layout/baseLayout"
import { graphql, Link } from "gatsby"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import ColumnLayout from "../components/layout/ColumnLayout"
import BusinessStory from "../components/about/BusinessStory"
import EmployeeList from "../components/about/EmployeeList"
import ArticleBox from "../components/solution/ArticleBox"

const IndexPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <SEO title={t("About")} isHome={false} />
      <BaseLayout>
        <div>
          <ColumnLayout
            title={t("About-Title")}
            description={
              <div>
                <div>
                  <Trans>About-Description</Trans>
                </div>
                <Link to="/contact" className="mt-6 btn-solid">
                  <Trans>Contact-Us-Button</Trans>
                </Link>
              </div>
            }
          >
              <EmployeeList />
          </ColumnLayout>
        </div>
        <ArticleBox
          count={3}
          prefix={"blog-"}
          headline={t("Index-Articles-Headline")}
          archiveButtonName={t("Index-Articles-Button")}
          archiveLink={"/blog"}
          className="bg-slate-50"
        />
        <BusinessStory />
      </BaseLayout>
    </>
  )
}

export default IndexPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
