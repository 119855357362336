import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { langFileFilter } from "../../util/langUtils"
import Employee from "./Employee"

const EmployeeList = () => {
  const { t, i18n } = useTranslation()
  const { language } = i18n

  const data = useStaticQuery(graphql`
    query {
      team: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/team/" } }
        sort: { fields: [frontmatter___date], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              date
              name
              role
              description
              linkedin
              email
              link
              image {
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            fileAbsolutePath
          }
        }
      }
    }
  `)
  const people = langFileFilter(data.team.edges, language)

  return (
    <div className="lg:col-span-3 grid md:grid-cols-2 gap-x-8 gap-y-20 mb-16">
      {people.map(
        ({
          node: {
            frontmatter: {
              date,
              name,
              role,
              description,
              linkedin,
              email,
              image,
              link,
            },
          },
        }) => (
       //   <Employee
       //     key={name}
       //     description={description}
       //     name={name}
       //     position={role}
       //     email={email}
       //     image={image.childImageSharp.fluid}
       //     linkedin={linkedin}
       //     link={link}
       //   />
       null
        )
      )}
    </div>
  )
}

export default EmployeeList
