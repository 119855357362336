import React, { useEffect, useRef } from "react"
import { Trans, useTranslation } from "react-i18next"
import { sr, srConfig } from "../../util/sr"
import ShiftedBoxIcon from "../common/ShiftedBoxIcox"

interface IBusinessStoryProps {}

const BusinessStory = ({}: IBusinessStoryProps) => {
  const { t } = useTranslation()

  const headlineRef = useRef(null)
  const contentRefs = [useRef(null), useRef(null), useRef(null)]

  useEffect(() => {
    if (sr && headlineRef.current && !contentRefs.some(ref => !ref.current)) {
      sr.reveal(headlineRef.current, srConfig())
      sr.reveal(contentRefs[0].current, srConfig(300))
      sr.reveal(contentRefs[1].current, srConfig(500))
      sr.reveal(contentRefs[2].current, srConfig(700))
    }
  }, [])

  const items = [
    {
      ref: contentRefs[0],
      year: "2020",
      items: [
        { headline: t("2020-Headline-1"), text: t("2020-Text-1") },
        { headline: t("2020-Headline-2"), text: t("2020-Text-2") },
        { headline: t("2020-Headline-3"), text: t("2020-Text-3") },
      ],
    },
    {
      ref: contentRefs[1],
      year: "2021",
      items: [
        { headline: t("2021-Headline-1"), text: t("2021-Text-1") },
        { headline: t("2021-Headline-2"), text: t("2021-Text-2") },
      ],
    },
    {
      ref: contentRefs[2],
      year: "2022",
      items: [{ headline: t("2022-Headline-1"), text: t("2022-Text-1") }],
    },
  ]
  return (
    <div className="relative w-full pt-12 -mb-12 pb-28 ">
      <div className="base-container">
        <div ref={headlineRef} className="load-hidden">
          <div className="text-center pre-heading md:text-left">
            <Trans>Vita</Trans>
          </div>
          <h1 className="text-center md:text-left">
            <Trans>Business-Story-Headline</Trans>
          </h1>
        </div>
        <div className="grid grid-cols-1 md:mt-20 md:grid-cols-2 lg:grid-cols-3 md:gap-x-12">
          {items.map((item,index) => (
            <div ref={item.ref} className="text-center md:text-left" key={item.year}>
              <ShiftedBoxIcon
                className="mx-auto mt-20 mb-16 lg:mt-0"
                heightClass="h-28"
                widthClass="w-28"
                renderIcon={() => (
                  <div className="text-3xl font-bold">{item.year}</div>
                )}
              />
              {item.items.map((entry,index) => (
                <div key={index}>
                  <div className="mt-6 font-bold">
                    <Trans>{entry.headline}</Trans>
                  </div>
                  <div className="">
                    <Trans>{entry.text}</Trans>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default BusinessStory
